@import '../../styles/customMediaQueries.css';


.fieldRoot {
  z-index: 9999999;
}

.labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 25px;
  margin-top: 16px !important;
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
/*   width: calc(100vw - 48px); */
/*   margin: 0 24px; */

  /* Gutter between inputs (when calendar is not visible) */
  & .startDateLabel {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
/*     flex-basis: calc(50vw - 30px); */
    color: var(--successColor);
    color: #1c7881;
    font-size: 18px;
    font-weight: 500 !important;
  }
  & .endDateLabel{
 /*    flex-basis: calc(50vw - 30px); */
    color: var(--successColor);
    float: right;
    color: #1c7881;
    font-size: 18px;
    font-weight: 500 !important;
  }

  & .input {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    transition: all 0.15s ease-out;
  }

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;

    /* Gutter between inputs (when calendar is not visible) */
/*     & .startDateLabel,
    & .endDateLabel {
      flex-basis: calc(50% - 6px);
    } */

    & .input {
      flex-basis: calc(50% - 6px);
      transition: all 0.15s ease-out;
    }
  }
}
