@import '../../../../styles/customMediaQueries.css';


.container {
  max-width: 92%;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    margin: 0 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.title{
  margin-bottom: 0.25em;
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}

.treeDescription {
  font-family: var(--fontFamily);
}

.responsiveImage {
  width: 100%;
  display: block;
  margin-bottom: 3em;
  margin-top: 1em;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
