
.icon {
  height: 42px;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 2px;

  @media (max-width: 768px) {
    margin: 0 20px;
  }
  @media (max-width: 550px) {
    margin-left: 22px;
    height: 38px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.langSwitchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 3px;
  height: 100%;
  margin: 0px 15px;
  margin-top: 90px;
  @media screen and (max-width: 550px) {
    margin-top: 0px;
    margin: 0px 0px;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    height: 60px;
    padding-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

.labelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  height: 45px;
  width: 55px;
  @media (max-width: 550px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    z-index: 99999999999;
    &:hover {
      background-color: var(--marketplaceColorLight);
    }
  }
}

.mobileLabel {
  z-index: 99999999999;
  @media (min-width: 550px) {
    display: none;
  }
}

.mobileLabelMobile {
  @media (min-width: 550px) {
    display: none;
    color: black;
    margin-left: -25px !important;
  }
}

.profileMenuLabel {
  @media screen and (max-width: 550px) {
    display: flex;
    justify-items: space-between;
    font-size: 25px;
    padding-top: 20px;
    margin-top: 10px;
    gap: 6px;
    text-align: center;
    align-items: center;
  }
}

.profileMenuContentMobile {
  z-index: 99999999999;
  max-width: 55px !important;
  width: 55px !important;
  @media screen and (max-width: 550px) {
    width: 100% !important;
    max-width: 100% !important;
    z-index: 99999999999;
  }
}


.languageSwitcher {
  position: relative;
  display: inline-block;
}

.switcherButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 12px;
}

.dropdown {
  position: absolute;
  top: 80%;
  left: 0;
  margin-top: -74px;
  background: #1a1a1a;
  border-radius: 4px;
  overflow: hidden;
  min-width: 120px;
  z-index: 1000;
}

.languageOption {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: transparent;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 5px;
}

.languageOption:hover {
  background: rgba(255, 255, 255, 0.1);
}

.flagIcon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 2px;
}

