@import '../../../../../styles/customMediaQueries.css';


.starContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  z-index: 1 !important;
}

.starContainer svg {
  margin-right: 0.5em;
  margin-bottom: 0;
  position: relative;
  z-index: 0;
}
.starContainer p {
  margin-right: 0.5em;
  font-size: 1rem;
}
.starContainer span {
  margin-right: 0.5em;
}
.reviewsHeading {
  text-decoration: underline;
  font-weight: 500;
  font-family: (--FontFamily);
  min-width: fit-content;
  cursor: pointer;
  color: black;
  padding-bottom: 0.5em;
}
.reviewsHeading:hover {
  transform: translateY(-2px);
}

.reviewsHeading:active {
  transform: translateY(1px);
}
.reviewsHeadingUnclickable {
  color: lightgrey;
}

.root {
  position: relative;
  width: 23px;
  height: 23px;
}

.starPath {
  stroke: #1c7881;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.starPathUnclickable {
  stroke: #d3d3d3;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/*specific classes for rerendering in other parts of the listing page*/

.starReviews {
  justify-content: flex-start;
  font-family: Noto Sans JP;
  font-weight: 600;
  margin-bottom: 8px;
}

.starReviews svg {
  margin-right: 0.3em;
}
.starReviews p {
  margin-right: 0.3em;
  color: var(--successColor) !important;
  font-family: Noto Sans JP;
}
.starReviews span {
  margin-right: 0.3em;
}

.starReviews > .reviewsHeading {
  text-decoration: none;
}

.starReviewsUnclickable {
  color: #d3d3d3;

  justify-content: flex-start;
}
.reviewsNumberUnclickable {
  color: #d3d3d3;
}
.starReviewsUnclickable svg,
.starReviewsUnclickable span,
.starReviewsUnclickable p {
  margin-right: 0.3em;
}

.noRatingStar {
  margin-top: -15px;
  display: flex;
}

.blackpointHostModal {
  color: black;
  position: relative;
  top: 2px;
/*   left: 10px; */
}

.star {
  padding-top: 4px !important;
}
