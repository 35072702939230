@import '../../styles/customMediaQueries.css';

body {
  background-repeat: no-repeat;
}

.sectionWrapper {
  width: 90vw;
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  margin: 2rem auto;
}

.sectionHeader {
  composes: h2 from global;
  width: 100%;
  text-align: left;
  color: var(--successColor);
}


.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vh 5vw 0 5vw;

  @media (--viewportMedium) {
    padding: 5vh 10vw 0 10vw;
  }

  @media (min-width: 1200px) {
    padding: 5vh 20vw 0 20vw;
  }
}


.imageContainer {
  margin-right: 20px;
}

.reportImage {
  max-height: 36vh;
  box-shadow: 5px 5px 20px rgba(78, 78, 78, 0.5);
}

.textContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 1vw;

  @media (--viewportMedium) {
    padding-left: 5vw;
  }
}

.container {
  position: relative;
  left: -90px;
  top: -130px;
  width: 150px;

  @media (--viewportSmall) {
    left: -130px;
    top: -150px;
    width: 200px;
  }

  @media (--viewportMedium) {
    left: -150px;
    top: -150px;
    width: 220px;
  }

  @media (min-width: 1200px) {
    width: 240px;
    top: -180px;
    background: rgba(0, 0, 0, 0);
    /* transform: rotate(-2deg) skew(2deg) scale(0.8); */
    transition: 0.5s;
  }

}

.container img {
  position: absolute;
  width: 100%;
  transition: 0.5s;
}

@media (min-width: 1200px) {
  .container:hover img:nth-child(4) {
    transform: translate(80px, -80px);
    opacity: 1;
  }

  .container:hover img:nth-child(3) {
    transform: translate(60px, -60px);
    opacity: 0.8;
  }

  .container:hover img:nth-child(2) {
    transform: translate(40px, -40px);
    opacity: 0.6;
  }

  .container:hover img:nth-child(1) {
    transform: translate(20px, -20px);
    opacity: 0.4;
  }
}
