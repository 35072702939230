@import '../../styles/customMediaQueries.css';


:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.worksSectionHeader {
  composes: marketplacePaperBackground from global;

  z-index: 1;
  width: auto;
  line-height: 34px;
  position: relative;
  font-family: var(--fontFamilyTitle);
  margin-left: 0;
  text-align: left;
  padding-left: 20px;
  font-size: 24px;
  margin-top: 4vh;

  @media (--viewportMedium) {
    text-align: left;
    margin-top: 7vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }

  @media (min-width: 400px) and (max-width: 700px) {
    margin-top: 4vh;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    /* margin-bottom:10px */
    margin-bottom: -74px;
  }
  @media (min-width: 1440px) and (max-width: 2800px) {
    /* margin-bottom:10px */
    margin-bottom: -74px;
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    margin-top: 13vh;
    padding-left: 1em;
  }

  @media (max-height: 400px) {
    margin-top: 4vh;
  }

  @media (max-height: 500px) and (min-width: 800px) {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    margin-top: 4vh;
    padding-left: 0;
    margin-left: 13vh;
  }

  @media (min-width: 1500px) {
    margin-bottom: -5rem;
  }
}

.section {
  position: relative;
  height: auto;
  overflow: hidden;

  @media (--viewportMedium) {
    min-height: 70vh;
  }
}

.section1a {
  position: relative;
  height: auto;
  overflow: hidden;

  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
  }
}

.section2 {
}

.section4 {
  z-index: 1;
  margin-bottom: 20px;

  /* @media (--viewportMLarge) {
    margin-top: -10vh;
  } */
}

.section5 {
  z-index: 0;
  min-height: 60vh;
}

.loader {
  width: 100%;
  height: 100%;
  composes: marketplacePaperBackground from global;
}

.loaderImage1 {
  position: absolute;
}

.loaderImage2 {
  position: absolute;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.topbar {
  opacity: 0;
  z-index: 999;
  /* backdrop-filter: blur(10px); */
  /* background-color: rgba(255,255,255,0.7); */
  @media (max-width: 1400) {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.topbarScrolled {
  opacity: 0;
  z-index: 999;
  /* backdrop-filter: blur(10px); */
  background-color: rgba(255, 255, 255, 0.7);
}

.dots {
  margin-top: -60vh;
  margin-left: 50px;
  width: 10vw;
  opacity: 0;
}

.dot_active {
  background-color: var(--marketplaceColorSecond);
  border: 1px solid var(--marketplaceColorSecond);
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 100%;
  margin-top: 10px;
  transition: 0.5s;
}

.dot {
  background-color: transparent;
  border: 1px solid var(--marketplaceColorSecond);
  width: 10px;
  height: 10px;
  margin-left: 5px;
  position: relative;
  border-radius: 100%;
  margin-top: 10px;
  transition: 0.5s;
}

.dotInner {
  width: 100%;
  height: 100%;

  position: absolute;
}

.LeftFooterContent {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 80px;
    z-index: 10;
    position: fixed;
    bottom: 60px;
    margin-left: 50px;
    transition: 1s;
  }
}

.LeftFooterContentHide {
  height: 0px;
  opacity: 0;
  transition: 1s;
}

.socialmedia {
  width: 50px;
}

.line {
  height: 1px;
  width: 65vw;
  margin-left: -14vw;
  background-color: black;
  margin-top: 200px;
  margin-bottom: 0px;
}

.HeaderWrapper {
  margin-top: 8vh;
  margin-left: 0vw;
  z-index: 99;
  position: relative;

  @media (--viewportMedium) {
    margin-top: 12vh;
  }
}

.HeaderWrapperRow {
  width: 100vw;
  height: auto;
  z-index: 5;
  overflow: hidden;

  @media (--viewportMedium) {
    height: 7em;
  }

  @media (--viewportMLarge) {
    height: 9em;
    margin-top: 13vh;
  }

  @media (--viewportLarge) {
    height: 9em;
    margin-top: 13vh;
  }
}

.Header1 {
  text-align: center;
  margin-top: 0px;
  width: 100vw;
  margin-left: 0vw;
  margin-top: 5px;

  z-index: 5;
  font-family: var(--fontFamilyTitle);
  font-weight: bold;
  color: var(--marketplaceColorDark);

  font-size: 60px;
  line-height: 62px;

  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportMedium) {
    width: 100%;
    font-size: 70px;
    line-height: 104px;
    flex-direction: row;
  }

  @media (--viewportMLarge) {
    font-size: 80px;
    line-height: 114px;
    display: flex;
  }

  @media (--viewportLarge) {
    font-size: 80px;
    line-height: 114px;
    display: flex;
  }

  @media (--viewportLarge) {
    font-size: 80px;
    line-height: 114px;
    display: flex;
  }
}

.HeaderWrapperRowSecond {
  width: 95vw;
  height: 7em;
  z-index: 3;
  margin-top: 70px;
  position: relative;

  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 0vh;
    height: 8em;
  }

  @media (--viewportMLarge) {
    height: 8em;
  }
}

/* .bookingSocialbnb{
  position: absolute;
  width: 100vw;
  text-align: center;
  margin-top: 74vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  animation: startAnimation 6s;


}
.bookingSocialbnb h3{
color: var(--marketplaceColorDark);
padding-right: 10px;
transform: scale(1.05);

} */

.vision {
  position: relative;
  text-align: center;

  width: 90vw;
  margin-left: 5vw;
  opacity: 0;
  display: flex;
  justify-content: center;

  /* color: var(--marketplaceColorSecond); */
  

  @media (--viewportMedium) {
    width: 100vw;
    margin-left: 0;
    font-size: 24px;
    line-height: 34px;
  }
}

.vision h3 {
  color: var(--marketplaceColorDark);
  padding-right: 10px;
  font-size: 18px;
  line-height: 28px;

  @media (--viewportMedium) {
    font-size: 22px;
    line-height: 28px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */

  width: 155px;

  padding-left: 7px;
  padding-right: 7px;
}

.heroWrapper_active {
  position: relative;
  z-index: 0;
  clip-path: circle(100% at 50% 50%);
  /* animation: heroImageanimation 0.8s cubic-bezier(.7,.26,.96,.43);  */
  will-change: auto;
  -webkit-animation: heroImageanimation 1.4s cubic-bezier(0.42, 0, 0.58, 1);
  animation: heroImageanimation 1.4s cubic-bezier(0.42, 0, 0.58, 1);
  margin-top: 0;
  overflow: hidden;
  height: 100vh;

  @media (--viewportMedium) {
    height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }

  @media (max-height: 400px) {
    height: 150vh;
  }
}

.heroWrapper {
  position: relative;
  top: auto;
}

.heroImage {
  object-fit: cover;
  object-position: auto;
  position: absolute;
  height: 100vh;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
}

.cloudsOuter {
  will-change: auto;

  /* margin-left: -35%; */
  animation: cloudsanimation 50s ease-in-out infinite;
  transform: translate3d(-35vw, 0px, 0px);
  z-index: 0;
  top: 0;
  position: absolute;
  width: 100vw;
}

.clouds {
  will-change: auto;
  opacity: 0.9;

  clip-path: circle(100% at 50% 40%);

  /* animation: startAnimation 1s cubic-bezier(.42,0,.58,1) ; */
  width: 550%;
  margin-top: -15vh;
  margin-left: -200%;

  @media (--viewportMedium) {
    opacity: 0.8;
    z-index: 9;
    position: absolute;
    width: '80vw';
    top: 0;
    margin-top: -15vh;
    margin-left: 0;
    width: 150%;
  }

  @media (max-width: 800px) and (max-height: 1020px) {
    opacity: 0.7;
    postition: absolute;
    top: 15vh;
    height: 100vh;
  }

  @media (max-width: 1024px) and (min-height: 1020px) {
    top: 12vh;
  }
}

.clouds1 {
  will-change: auto;

  clip-path: circle(100% at 50% 40%);

  /* animation: startAnimation 1s cubic-bezier(.42,0,.58,1) ; */
  width: 550%;
  margin-top: -15vh;
  margin-left: -200%;

  @media (--viewportMedium) {
    margin-top: -15vh;
    margin-left: 0;
    width: 150%;
    z-index: 9;
    position: 'absolute';
    width: '80vw';
    top: '49.3vh';
    height: '41vh';
    transform: 'scaleY(-1)';
  }
}

/* .clouds2 {
  margin-top: 5vh;
  will-change: auto;

  clip-path: circle(100% at 50% 40%);
} */

@keyframes cloudsanimation {
  0% {
    transform: translate3d(-35vw, 0px, 0px);
  }

  50% {
    transform: translate3d(-40vw, 0px, 0px);
  }

  100% {
    transform: translate3d(-35vw, 0px, 0px);
  }
}

@keyframes heroImageanimation {
  0% {
    clip-path: circle(0.8% at 50% 50%);
    transform: scale(1.3);
  }

  1% {
    clip-path: circle(0.8% at 50% 50%);
    transform: scale(1.3);
  }

  100% {
    clip-path: circle(100% at 50% 50%);
    transform: scale(1);
  }
}

.formControl {
  composes: marketplaceButtonStylesCustom from global;
  z-index: 0;
  opacity: 1;
  border-bottom-right-radius: 0;
  border-top-right-radius: 11px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 11px;
  position: relative;
  animation: startAnimation 5s;
  width: 90vw;

  @media (--viewportMedium) {
    position: relative;
    width: 300px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 11px;
    border-top-left-radius: 11px;
  }
}

.heroButton {
  composes: marketplacePaperBackground from global;
  composes: marketplaceButtonStyles from global;
  border-radius: 5px;
  margin-left: 20px;
  /* border-bottom-right-radius: 11px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 11px;
  border-top-left-radius: 0; */
  z-index: 0;
  position: relative;
  animation: startAnimation 3s;
  width: 90vw;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);

  @media (--viewportMedium) {
    position: relative;
    width: 250px;
    border-radius: 5px;
    /* border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; */
  }
}

@media screen and (max-width: 576px) {
  .heroButton {
    margin-left: 0;
    margin-top: 10px;
    width: 80vw;
  }
}

@keyframes startAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sectionHeader {
  composes: marketplacePaperBackground from global;

  z-index: 1;
  width: auto;
  line-height: 34px;
  position: absolute;
  font-family: var(--fontFamilyTitle);
  margin-left: 20px;
  text-align: left;
  padding-left: 20px;
  font-size: 25px;
  margin-top: 11vh;

  @media (--viewportMedium) {
    text-align: center;
    margin-top: 15vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }
}

.sectionHeader2 {
  composes: marketplacePaperBackground from global;

  z-index: 1;
  width: auto;
  line-height: 34px;
  position: absolute;
  font-family: var(--fontFamilyTitle);
  /* margin-left: 30px; */
  text-align: left;
  padding-left: 20px;
  font-size: 34px;
  margin-top: 4vh;

  @media (--viewportMedium) {
    text-align: center;
    margin-top: 7vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }

  @media (min-width: 360px) and (max-width: 700px) {
    line-height: 35px;
    font-size: 24px;
  }
  @media (max-width: 425px) {
    margin-top: 20vh;
  }
  @media (max-width: 375px) {
    margin-top: 12vh;
  }

  @media (min-width: 200px) and (max-width: 300px) {
    font-size: 24px;
    line-height: 25px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: 14vh;
    padding-left: 1em;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    margin-top: 14vh;
    padding-left: 1em;
  }
  @media (min-width: 1441px) {
    margin-top: 12vh;
    padding-left: 1em;
  }
}

.sectionHeader3 {
  composes: marketplacePaperBackground from global;

  z-index: 1;
  width: auto;
  line-height: 34px;
  position: relative;
  font-family: var(--fontFamilyTitle);
  margin-left: 0;
  text-align: left;
  padding-left: 20px;
  font-size: 24px;
  margin-top: 4vh;

  @media (--viewportMedium) {
    text-align: left;
    margin-top: 7vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }

  @media (min-width: 400px) and (max-width: 700px) {
    margin-top: 4vh;
  }

  @media (min-width: 700px) and (max-width: 1024px) {
    margin-top: 13vh;
    padding-left: 1em;
  }

  @media (max-height: 400px) {
    margin-top: 4vh;
  }

  @media (max-height: 500px) and (min-width: 800px) {
    margin-top: 0;
  }
}

.lineCarousel,
.lineCarousel2 {
  position: absolute;
  height: 1.5px;
  width: 100vw;
  left: 0vw;
  float: left;
  background-color: var(--marketplaceColor);
  margin-top: 23vh;
}

.lineCarousel {
  @media (--viewportMedium) {
    margin-top: 10vh;
  }

  @media (max-width: 400px) {
    margin-top: 16vh;
  }
}

.lineCarousel2 {
  @media (--viewportMedium) {
    margin-top: 17.5vh;
  }
}

/* .carousel {
  clip-path: polygon(0 100%, 100% 100%, 100% -20%, 0 -20%);
  transition: 2s cubic-bezier(0.7, 0, 0.68, 1);
  background-color: #fafafa;
  background-image: url('https://www.transparenttextures.com/patterns/groovepaper.png');

  background-size: 400px 240px;
  background-repeat: repeat;


}

.carousel_open {

  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  transition: 2s cubic-bezier(0.7, 0, 0.68, 1);



} */

.reviews {
  width: 60vw;
  margin-top: 5vh;
}

/* .Filter {
  position: absolute;
  text-align: left;
  font-size: 1em;

  margin-top: -89vh;
  margin-left: 70vw;

  width: 65vw;
  color: var(--marketplaceColorSecond);
  pointer-events: none;
} */

.sectionAlternative {
  min-height: 100vh;
  position: relative;
}

.fourthWrapper {
  min-height: 100vh;
  position: relative;
}

h3.subline1 {
  float: left;

  left: 0;
  width: 90px;
  height: 1px;
  margin-right: 20px;

  background-color: white;
  background-color: #353535;
}

.cardSubtitle {
  color: white;
  text-align: left;
  color: #335353;
}

.Card {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  border-radius: 20px;
}

.innerCard {
  width: 60%;
  height: 70vh;
  overflow: hidden;
  border-radius: 20px;
}

.CardTitle {
  /* @apply --SectionHero_animation; */

  margin-left: 50px;
  margin-top: 50px;
  font-size: 34px;
  text-align: left;
  width: 90%;
  color: #353535;
  font-family: var(--fontFamilyTitle);
  letter-spacing: 2px;
}

.cardSide {
  float: right;
  width: 38%;
  height: 100%;
}

.cardSideTitle {
  font-size: 1.5em;
  color: rgb(100, 115, 227);
  color: #353535;
  color: var(--marketplaceColorSecond);
  color: var(--marketplaceColor);
  font-weight: 600;
  height: 15%;
}

.cardSideSubCardContainer {
  height: 85%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cardSideSubCard {
  width: 100%;
  height: 23%;

  border-radius: 20px;
  overflow: hidden;
}

/* .cardSideSubCard img {
} */

.cardButton {
  composes: marketplaceButtonStylesCustom2 from global;
  /* @apply --SectionHero_animation; */

  animation-delay: 0.8s;
  position: relative;
  display: block;
  width: 300px;
  border-radius: 10px;

  float: right;
  margin-top: -60px;
  margin-right: 2vh;
  margin-bottom: 2vh;
}

.reviewSubtitle {
  margin-left: 50px;
}

.NgoImage {
  width: 120%;
  left: -10%;
}

.reviewImage {
  height: 100%;
  left: -10%;
}

.quote {
  text-align: left;
  font-size: 8em;
  width: 100%;
  margin-top: 100px;
  margin-left: 50px;
  color: #1c7881;
  font-family: var(--fontFamilyTitle);
}

.brush {
  position: absolute;
  top: 165vh;
  width: 17%;
  right: 5%;
}

.garden {
  position: absolute;
  top: 273vh;
  width: 25%;
  left: 5%;
}

.review1 {
  position: absolute;
  top: 278vh;
  width: 27%;
  right: 5%;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;

  height: 85vh;
  padding: 0;

  /*
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  } */
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.menu {
  z-index: 19;
  position: fixed;
  background-color: white;
  width: 25em;
  height: 72px;
  top: 0;
  left: 16em;
  transition: 1s;
}

.menuText {
  color: #353535;
  padding: 40px;
  line-height: 72px;
}

.menuText:hover {
  color: #353535;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 21px;
  line-height: 72px;
  border-bottom: 4px solid var(--marketplaceColor);
}

.menuText_active {
  border-bottom: 4px solid var(--marketplaceColor);
  animation: menuborderanimation 1s;
  padding-bottom: 21px;
  line-height: 72px;
}

@keyframes menuborderanimation {
  0% {
    border-bottom: 4px solid white;
  }

  100% {
    border-bottom: 4px solid var(--marketplaceColor);
  }
}

.foto {
  width: 80%;
  margin-left: 10%;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.pageTitle {
  /* @apply --SectionHero_animation; */

  margin-top: 150px;

  font-size: 54px;
  line-height: 70px;
  text-align: left;
  width: 15em;
  float: left;

  z-index: -1;
}

.pageTitle_right {
  /* @apply --SectionHero_animation; */

  margin-top: 150px;
  font-family: Lora;

  font-size: 54px;
  line-height: 70px;
  text-align: right;
  width: 15em;

  right: 0;
  float: right;

  z-index: -1;
}

.contentWrapperOuter {
  clear: both;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.indicatorWrapper {
  position: absolute;
  margin-top: 110vh;

  right: 13.5vw;
}

.indicator {
  float: left;
  padding-left: 20px;
  font-size: 25px;
  line-height: 0px;
  font-weight: 900;
  color: #353535;
  font-family: Lora;
}

.indicator_thin {
  float: left;
  font-weight: 300;
  line-height: 0px;
  padding-right: 20px;
  font-size: 25px;

  font-family: Lora;

  color: #353535;
}

.contentMain {
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  @media (--viewportMedium) {
    max-width: 800px;
  }
}

.Wrapper {
  width: 100%;
  min-height: 100vh;

  composes: marketplacePaperBackground from global;
}

.SwipeButtonContainer {
  position: relative;
  margin-top: -80vh;
  float: right;
  margin-right: 10vw;
  padding-left: 20px;

  composes: marketplacePaperBackground from global;
}

.SwipeButton {
  margin-right: 20px;
  background-color: transparent;
  border: 1px solid var(--marketplaceColorSecond);
  float: left;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 100%;

  /* &:hover,
  &:focus {
    box-shadow: var(--boxShadowButton);
  } */
}

.SwipeButton :hover {
  box-shadow: var(--boxShadowButton);
}

.SwipeButton :focus {
  box-shadow: var(--boxShadowButton);
}

.SwipeButton_disabled {
  margin-right: 20px;
  background-color: transparent;
  border: 1px solid var(--marketplaceColorSecond);
  float: left;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 100%;
  opacity: 0.5;
  pointer-events: none;
}

.arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 10px;
  height: 10px;
  display: block;
}

.processImage1 {
  position: absolute;
  margin-top: 20vh;
  margin-left: 10vw;
  width: 100px;
}

.Select {
  background-color: transparent;
  padding-right: 40px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 16px;
  display: flex;
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}
