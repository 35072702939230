.root {
  margin-right: 0px;
}
.root label {
  color: var(--successColor);
}
.inputError {
  border: var(--borderErrorField);
}
.title {
  color: #1c7881;
  font-size: 1rem;
}
.textContent {
  margin-bottom: 1em;
}
.characterCount {
  display: flex;
  justify-content: flex-end;
}
