@import '../../styles/customMediaQueries.css';


.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  margin: 0;
  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.discountTextFinalContainer {
  margin-top: 30px;
}

.discountTextFinal {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
}

.dayLabel {
  letter-spacing: 2px !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.dayInfo {
  composes: textSmall from global;
  margin: 0;
  line-height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.totalPrice {
  composes: marketplaceSmallFontStyles from global;
  margin: 16px 0;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  margin: 0;
 /*  padding-top: 6px;
  padding-bottom: 6px; */

  display: flex;
  flex-direction: row;
 /*  align-items: flex-end; */
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  @media screen and (max-height: 720px) and (max-width: 850px) {
    margin: 0 0 -10px 0;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
}

.commissionLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 0.5px;
  margin: 12px 0 20px 0;

  border: none;
  background-color: black;

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}
.totalDividerMaybe{
  width: 100%;
  height: 0.5px;
  margin: 5px 0px 20px 0px !important;

  border: none;
  background-color: black;

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}
.invisibleTotalDivider {
  width: 100%;
  height: 0.5px;
  margin: -12px 0 5px 0;

  border: none;
  background-color: transparent;

  @media (--viewportMedium) {
    margin: -12px 0 0px 0;
    background-color: transparent;
  }
}

.totalLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
  margin: 16px 0;
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.lineItem2 {
  display: flex;
  justify-content: space-between;
}

.upfrontPaymentLabel {
  composes: marketplaceSmallFontStyles from global;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}

.discountData{
  margin-top: 20px;
}
.discountDataWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.discountTextFinal{
  font-size: 14px;
}
.discountPriceFinal{
  font-size: 16px;
}
.upfrontPaymentTitle{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.itemLabel2 {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
  margin-top: 28px;
  margin-bottom: -10px;
  display: flex;
  justify-content: space-between;
}


.discountDataWrapper{
  padding-top: 0px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -20px;
}


.lineItem3 {
  margin-top: 0px;
  margin-bottom: -20px;
  display: flex;
  justify-content: space-between;
}

.lineItem6 {
  composes: h4 from global;
/*   margin: 0; */

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totalPriceDiscount {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.2px;

  margin: 0;
  padding: 0;
  margin-top: 0px !important;
}
.lineItem7{
  display: flex;
  justify-content: space-between;

}

.dividerSpace{
  margin-top: 20px;
}

.paymentBreakdownSeparator{
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  flex-direction: column;
}