@import '../../styles/customMediaQueries.css';


/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  height: 100vh;
  background-color: white !important;
  position: relative;
  z-index: 1000000 !important;
  @media screen and (max-width: 750px) {
    margin-top: 150px !important;
    z-index: 1000000 !important;
    height: 350vh !important;
    position: relative;
    display: flex;
    /* composes: marketplaceModalInMobileBaseStyles from global; */
  }
  @media screen and (max-width: 850px) {
    margin-top: 150px !important;
  }
  @media screen and (max-height: 850px) and (max-width: 850px) {
    margin-top: -100px !important;
    z-index: 1000000 !important;
    height: 100% !important;
    position: absolute;
    border-radius: 10px !important;
  }

  bottom: 0vh;
  left: 0vw;
  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    border-radius: 10px !important;
  }

  @media (--viewportLarge) {
    padding: 2em 1em 1em 1em;
    background-color: white;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid black !important;
    border-radius: 10px !important;
  }
}

.bookingWrapper {
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 0.5em 1em 1em 1em;
  z-index: 100000;
  @media (min-width: 1280px) {
    padding: 0 2em 0.5em 2em;
  }
  @media screen and (max-width: 750px) {
    margin-top: 7vh;
    width: 99%;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 6px;
  }
}
.bookingContainer {
  @media screen and (max-width: 750px) {
    width: 98%;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
}

.perUnit {
}

.author {
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  gap: 5px;

  @media (--viewportMedium) {
    position: static;
    max-width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
  @media screen and (max-width: 750px) {
    margin: 0px 0 0px 0 !important;
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    position: relative;
    top: 10px;
    margin-right: 10px;
  }
  @media screen and (max-width: 750px) {
    display: flex;
    margin: 0px 0 0px 0 !important;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  z-index: 9999999999 !important;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden;

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px !important;
}

.priceContainerMobile {
  padding-top: 2px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000 !important;
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.bookingHeaderSection {
  width: 100%;
  height: 4em;
  margin-top: 20px;
  display: flex;
  gap: 0%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.roomTypeTitle {
  color: #1c7881;
  font: bold !important;
  font-size: 19px;
}
.bookingHeaderSubSection {
  display: flex;
  width: 98%;
}
.perRoomLabel {
  font-size: 17px;
}
.priceTag {
  color: #1c7881;
  font-size: 17px;
  white-space: nowrap;
}
.bedAmountField {
  width: 30%;
}

.bedAmountLabel {
  color: var(--colorGrey700);
  composes: h4 from global;
}
.bookButton {
  border: none;
  margin-left: 50px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 4px;
  background-color: #eb7242;
  color: white;
}
.priceAndRoomWrapper {
  margin-left: -5px;
}

.expImage {
  width: 50px;
  height: 50px;
}
.expImageContainer {
  padding-right: 12px;
}
