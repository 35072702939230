@import '../../../../styles/customMediaQueries.css';


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  padding: 2em 0;
  max-width: 92%;
  max-width: 1400px;
  margin: 1em auto;
  @media (max-width: 1024px) {
    margin: 1em 1em;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    margin: 1em 5em;
  }
  @media (min-width: 1440px) {
    margin: 1em 7em;
  }
  @media (min-width: 1920px) {
    margin: 1em auto;
  }
}
.containerReveresed{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  padding: 2em 0;
  max-width: 92%;
  max-width: 1400px;
  margin: 1em auto;
  @media (max-width: 1024px) {
    margin: 1em 1em;
  }
  @media (min-width: 1024px) {
    flex-direction: row-reverse;
    margin: 1em 5em;
  }
  @media (min-width: 1440px) {
    margin: 1em 7em;
  }
  @media (min-width: 1920px) {
    margin: 1em auto;
  }
}

.description {
  color: var(--matterColorDark);
  font-size: 1em;
  font-weight: 400;
  margin: 0 auto 1.5em; /* auto for left and right margins centers the block */
  width: 88%;
  text-align: center;
}

.textBlock {
  max-width: 50%;
  text-align: center;
  transform: translateX(-2em);
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.heading {
  margin-bottom: 1rem;
}

.contactButton {
  background-color: var(--marketplaceColor); /* Example button color */
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  width: 300px;
  font-size: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  &:hover {
    background-color: var(--marketplaceColorDark);
  }
  &:active {
    background-color: var(--marketplaceColor);
  }
}

/* WhatsApp row */
.whatsappRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem; /* space between icon and number */
}

.whatsappIcon {
  width: 2.5em;
  height: 2.5em;
  object-fit: contain;
  margin-bottom: 0.5em;
  @media (--viewportLarge) {
    width: 2em;
    height: 2em;
    margin-bottom: 0;
  }
}

.whatsappNumber {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.5em;
  @media (--viewportLarge) {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
}

/* Image section */
.imageBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportLarge) {
    translate: 250px 0;
    margin-top: 400px;
    max-width: 40%;
  }
}

.imageBlockReversed {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportLarge) {
    translate: -250px 0;
    margin-top: 400px;
    max-width: 40%;
  }
}

.imgClass {
  object-fit: cover;
  width: 260px;
  height: 210px;
  @media screen and (max-width: 490px) {
    width: 180px;
    height: 160px;
  }
}

.imgDesktWrapper {
  display: none;
  @media (--viewportLarge) {
    margin-right: 100px;
    display: flex;
    position: relative;
  }
}

.img1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  padding-top: 10px;
  left: 20px;
  bottom: 60px;
  transform: rotate(10deg);
  width: 280px;
  height: 310px;
  z-index: 2;
  transition: transform 0.5s ease-out;
  background-color: white !important;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
  }
  &:hover {
    transform: rotate(14deg);
  }
}
.img2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  padding-top: 10px;
  right: -170px;
  bottom: 80px;
  transform: rotate(-4deg);
  width: 280px;
  height: 310px;
  background-color: white !important;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: transform 0.5s ease-out;
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
  }
  &:hover {
    transform: rotate(0deg);
  }
}

.img3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  padding-top: 10px;
  bottom: 40px;
  left: -40px;
  transform: rotate(1deg);
  width: 280px;
  height: 310px;
  box-shadow: 10px solid black;
  background-color: white !important;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.3);
  z-index: 3;
  transition: transform 0.5s ease-out;
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
    bottom: 10px;
  }
  &:hover {
    transform: rotate(5deg);
  }
}

.img3content {
  display: flex;
  flex-direction: column;
}

.img3textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportLarge) {
    padding-top: 1em;
  }
}

.img3Text {
  text-align: center;
  font-size: 1rem;
  line-height: 1.3rem;
  font-family: Permanent Marker;
}

/* Mobile View */
.imgMobileWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (--viewportLarge) {
    display: none;
  }
}

.profileContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto 1.5em auto;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
}

.profileImageText {
  position: absolute;
  color: black;
  display: flex;
  flex-direction: column;
  bottom: 8%;
  left: 55%;
  text-align: center;
  rotate: -17deg;
}
