:root {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Default display for the pseudo-element */
  --custom-highlight-background: linear-gradient(
    90deg,
    var(--base-color) 0%,
    var(--highlight-color) 50%,
    var(--base-color) 100%
  );
}

/* Keyframes for the skeleton animation */
@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

/* Main skeleton element styles */
.react-loading-skeleton {
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;
}

/* Pseudo-element to create the animated highlight */
.react-loading-skeleton::after {
  content: '';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: var(--custom-highlight-background);
  transform: translateX(-100%);
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* Disable animation for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .react-loading-skeleton::after {
    animation: none;
  }
}

/* Container styles */
.container {
  width: 92%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 20px;
}
