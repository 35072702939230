@import '../../styles/customMediaQueries.css';

.navbarContainer {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  height: 75px;
  z-index: 9000000000000000;

  transition: background-color 0.4s ease, box-shadow 0.4s ease; /* Added transition */
  @media screen and (max-width: 550px) {
    padding: 0rem 0rem 0rem 1rem;
  }
  @media screen and (min-width: 550px) {
    padding: 0 2% 0 2%;
  }
  @media screen and (min-height: 900px) {
    padding: 0 1% 0 1%;
  }
  @media screen and (min-height: 900px) and (min-width: 1200px) {
    padding: 0 9% 0 9%;
  }
  @media screen and (min-height: 900px) and (--viewportXLarge) {
    padding: 0 20% 0 20%;
  }
}

.navbarWhiteBg {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
  transition: background-color 0.4s ease, box-shadow 0.4s ease; /* Added transition */
}

.navbarTransparentBg {
  background-color: transparent;
  transition: background-color 0.4s ease, box-shadow 0.4s ease; /* Added transition */
}
.blueBg {
  @media screen and (max-width: 550px) {
    background-color: #1c7881;
  }
}
.hideMainItems {
  @media screen and (max-width: 550px) {
    display: none;
  }
}

.mainWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 9999999;
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: fit-content;
  @media screen and (max-width: 1250px) {
    width: 94%;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}
.socialbnbLogo {
  height: 100%;
  max-width: 250px;
  @media screen and (max-width: 680px) {
    max-width: 40px;
  }
}

.logoImage {
  height: 22px;
  @media screen and (max-width: 680px) {
    display: none;
  }
}
.logoImageSmall {
  height: 34px;
  display: none;
  margin-top: -5px;
  @media screen and (max-width: 680px) {
    display: flex;
  }
}

.userIcon {
  margin-right: 12px;
  width: 35px;
  height: 35px;

  @media screen and (max-width: 550px) {
    display: none;
  }
}

.leftSideWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 4px;
  height: 75px !important;
  max-height: 75px;
  gap: 10px;
  z-index: 99999999;
  @media screen and (max-width: 550px) {
    gap: 0px;
  }
  @media screen and (max-width: 1250px) {
    margin-right: 20px;
  }
}

.becomePartnerWrapper {
  @apply --;
  height: 72px;
  width: 130px;
  display: flex;
  opacity: 1;
  @media screen and (max-width: 800px) {
    display: none;
  }
}
.favoriteWrapper {
  height: 72px;
  width: 130px;
  display: flex;
  opacity: 1;
  @media screen and (max-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 1100px) {
    pointer-events: none;
  }
}

.favouritesButton {
  composes: h5 from global;
  @apply --;

  flex-shrink: 0;
  font-weight: bold;
  height: 100%;
  padding: 0;
  color: #1c7881;
  font-size: 13px;
  margin-bottom: 0;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  width: 130px;
  height: 38px;
  margin-top: 16px !important;
  border: 1px solid #a7a5a5;
  border-radius: 16px;
  padding-top: 5px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.favouritesButton:hover {
  transition: background-color 0.3s ease-out, color 0.1s ease-out, border 0.3 ease-out;
  margin-top: 16px !important;
  background-color: var(--marketplaceColorDark);
  color: white;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 16px;
}
.favouritesButton:hover .heartIcon {
  transition: filter 0.2s ease-out;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
    brightness(100%) contrast(100%);
}

.becomePartnerWrapper_hide {
  display: none;
}
.becomePartnerLink {

  flex-shrink: 0;
  font-weight: bold;
  align-items: center;
  height: 100%;
  padding-top: 5px;
  color: #1c7881;
  font-size: 13px;
  margin-bottom: 0;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  width: 130px;
  height: 38px;
  margin-top: 16px !important;
  border: 1px solid #a7a5a5;
  border-radius: 16px;
  @media screen and (max-width: 940px) {
    translate: 140px 0;
  }
}
.becomePartnerLink:hover {
  transition: background-color 0.3s ease-out, color 0.1s ease-out, border 0.3 ease-out;
  margin-top: 16px !important;
  background-color: var(--marketplaceColorDark);
  color: white;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 16px;
}
.becomePartnerLink:hover .heartIcon {
  transition: filter 0.2s ease-out;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
    brightness(100%) contrast(100%);
}

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  align-items: center;
  height: 35px;
  @media screen and (max-width: 420px) {
    margin-left: -10px !important;
  }
  @media screen and (max-width: 940px) {
    width: auto;
  }
  @media screen and (min-width: 1101px) {
    width: 400px;
  }
}
.searchButton {
  cursor: pointer;
  position: absolute;
  right: 0px;
  transform: translateX(-0.5px);
  z-index: 999;
  background-color: #eb7242;
  color: white;
  border: none;
  border-left: 1px solid black;
  border-radius: 0px 5px 5px 0px;
  padding: 5px 10px;
  margin-left: 10px;
  width: 100px;
  height: 37px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.searchInput:hover,
.searchInput:focus {
  border: 1px solid black;
  border-radius: 6px;
}

.searchInput {
  padding-left: 40px;
  padding-right: 30px;
  position: relative;
  z-index: 0;
  border: 1px solid black;
  border-radius: 6px;
  width: 400px;
  height: 39px;
  @media screen and (max-width: 1100px) {
    width: 250px;
  }
  @media screen and (max-width: 360px) {
    width: 200px;
    padding-right: 20px;
  }
}

.searchIcon {
  position: absolute;
  margin-top: 2px;
  margin-left: 10px;
  z-index: 999;
  transform: scaleX(-1);
  /*   margin-top: 10px; */
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  top: 36px !important;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  background-color: #1c7881;
  overflow: hidden;
}

.heartIcon {
  width: 28px;
  height: 25px;
  margin-right: 6px;
}

.menWrapper {
  position: relative;
  width: 100%;
  translate: 30px 6px;
  z-index: 9999999999;
}
.avatarHover {
  cursor: pointer !important;
  background-color: rgba(0, 0, 0, 0) !important;
  z-index: 999;
  width: 80px !important;
  width: 75px !important;
  display: flex !important;
  @media screen and (max-width: 350px) {
    margin-left: 0px !important;
  }
}
.avatarMenuUser {
  margin-top: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  width: 80px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 350px) {
    margin-left: 0px;
  }
}

.guestHover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  @media screen and (max-width: 550px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 350px) {
    margin-left: 0px;
  }
}

.avatar {
  margin: 0px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  @media screen and (max-width: 550px) {
    display: none;
  }
}

.burgerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border: 1px solid #a7a5a5;
  padding: 3px 3px 3px 10px;
  height: 45px;
  width: 80px;
  z-index: 9999999999;
  border-radius: 15px;
  @media screen and (max-width: 550px) {
    padding: 3px 3px 3px 3px;
    width: 50px;
  }
  @media (--viewportMedium) {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.burgerMenu:hover {
  @media screen and (--viewportLarge) {
    border: 2px solid var(--marketplaceColorDark);
  }
}

@media screen and (max-width: 550px) {
  .burgerMenu {
    border: none !important;
  }
}

.burger {
  position: relative;
  width: 20px;
  height: 16px;
  background: transparent;
  cursor: pointer;
  display: flex;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.languageSwitch {
  margin-top: -90px;
  @media screen and (max-width: 550px) {
    display: none;
  }
}
.languageSwitchGuest {
  margin-top: -90px;
  @media screen and (max-width: 550px) {
    display: none;
  }
}

.avatarWrapper {
  z-index: 9999999999;
  display: flex;
}

.closeIcon {
  position: absolute;
  right: 30px;
  height: 30px;
  width: 30px;
  display: none;
  @media screen and (max-width: 550px) {
    display: flex;
  }
}
.menuBugerIconUser {
  display: none;
  @media screen and (max-width: 550px) {
    display: flex;
    height: 35px;
    width: 35px;
    padding-top: 0px;
    margin-top: 0px;
    margin-left: 5px;
  }
}
@media screen and (min-width: 550px) {
  .menuBugerIcon {
    display: none !important;
  }
}

.menuBugerIcon {
  position: absolute;
  right: 30px;
  height: 35px !important;
  width: 35px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;

  @media screen and (min-width: 550px) {
    display: none !important;
  }
}
.menuBugerIconUser {
  display: none !important;
  @media screen and (max-width: 550px) {
    display: flex !important;
    height: 35px !important;
    width: 35px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-left: 5px !important;
  }
}

.menuBugerIconFull {
  height: 35px !important;
  width: 35px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  margin-left: 5px !important;
  display: flex !important;
  @media screen and (max-width: 550px) {
    display: none !important;
  }
}
.menuBugerIconSmall {
  height: 35px !important;
  width: 35px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  margin-left: 5px !important;
  display: flex !important;
  @media screen and (min-width: 550px) {
    display: none !important;
  }
}

.userAvatar {
  pointer-events: none;
  display: flex;
  /*   position: absolute;
  top: -38px;
  left: 28px; */
  margin-right: 10px !important;
  width: 34px !important;
  height: 32px !important;
}

.hideForDesktop{
  @media screen and (min-width: 550px) {
    display: none;
  }
}

.hideForMobile{
  @media screen and (max-width: 550px) {
    display: none;
  }
}
