@import '../../../../styles/customMediaQueries.css';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landinpageContainer {
  display: flex;
  flex-direction: column;
}

.heroSectionParts {
  display: flex;
}

.heroWrapper {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent overflow */
}

.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  z-index: 0; 

}

.heroBackgroundStatic {
  width: 100%;
  height: 100%; /* Fill the container height */
  object-fit: cover; /* Maintain aspect ratio */
  filter: brightness(65%);
}

.heroBackground::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0) 25%);
  pointer-events: none;
  z-index: 1; /* Ensure the gradient is above the image */
}

.textWrapper {
  position: relative;
  z-index: 2;
  max-width: 1400px;
  margin: 0 auto;
  padding: 5em 0em 3em 0em;
  @media (max-width: 1024px) {
    margin: 0 1em;
    padding: 5em 0em 4em 0em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.textSectionWrapper {
  top: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  margin: 0 auto;
  @media (--viewportMedium) {
    top: 16%;
  }
}

.headerWrapperRow {
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 1rem;
  width: 100%;
  font-family: Noto Sans JP;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #fff;
  margin-bottom: 0;
  margin-right: 0;
  @media (--viewportSmall) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  @media (min-width: 380px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0.1em;
  }
  @media (--viewportLarge) {
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 4.5rem;
    margin-bottom: 0.2em;
  }
  @media (--viewportXLarge) {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 5.1rem;
    margin-bottom: 1vh;
  }
}

.subHeader {
  color: white;
  width: 60%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  text-align: left;
  @media (--viewportMedium) {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  @media (--viewportLarge) {
    font-size: 1rem;
    line-height: 2rem;
    width: 65%;
  }
}
/*Hero Image Text Section END*/
/* Category buttons */
.categoryButtons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 40px auto 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100%;

  @media screen and (--viewportMedium) {
    max-width: 1400px;
  }
}

.button {
  flex: 1;
  margin: 0;
  padding: 0 5px;
  border: 2px solid white;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  will-change: transform;
  transition: background-color 0.8s ease-out, color 0.3s, border 0.3s;
  position: relative;

  @media screen and (--viewportSmall) {
    padding: 5px 10px;
    font-size: 14px;
  }
  @media screen and (--viewportMedium) {
    padding: 5px 10px;
    font-size: 16px;
  }
  @media screen and (--viewportLarge) {
    padding: 15px 30px;
    font-size: 16px;
  }
}

.button:hover {
  animation-play-state: paused;
  transform: scale(1.01);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4), 0 0 20px rgba(255, 255, 255, 0.1); /* Glowing effect */
}

.button:active {
  transform: scale(0.98);
}

.button:not(.active) {
  animation: bn13bouncy 9s infinite ease-in-out;
}

.button:nth-child(1):not(.active) {
  animation-delay: 1s;
}

.button:nth-child(2):not(.active) {
  animation-delay: 3s;
}

.button:nth-child(3):not(.active) {
  animation-delay: 6s;
}

.button:nth-child(4):not(.active) {
  animation-delay: 9s;
}

.active {
  background-color: white;
  color: var(--marketplaceColorDark);
}

.active:hover {
  color: var(--marketplaceColorDark);
}

@keyframes bn13bouncy {
  0% {
    top: 0em;
  }
  40% {
    top: 0em;
  }
  43% {
    top: -0.3em;
  }
  46% {
    top: 0em;
  }
  48% {
    top: -0.1em;
  }
  50% {
    top: 0em;
  }
  100% {
    top: 0em;
  }
}

/* Media query for larger screens */
@media (--viewportMedium) {
  .categoryButtons {
    max-width: 1400px;
  }
}

/* Category Buttons END */

.pressSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0 0 0;
  color: white;
  gap: 10px;
  width: 100%;
  @media (--viewportMedium) {
    gap: 30px;
  }
}

.pressSection img {
  max-height: 21px;

  @media (--viewportMedium) {
    max-height: 36px;
  }
}

.pressSection a {
  filter: brightness(0.95);
  &:first-child img {
    max-height: 40px;
  }
  @media (--viewportMedium) {
    &:nth-child(2) img {
      max-height: 60px;
    }
  }

  &:nth-child(7) img {
    filter: brightness(2);
  }

  cursor: pointer;
  &:hover {
    scale: 1.03;
    filter: brightness(1.1);
  }
  &:active {
    scale: 0.97;
  }
}

.pressText {
  text-align: center;
  font-weight: 500;
  width: 100px;
  line-height: 1.5;
  font-size: 0.7em;
  @media (--viewportMedium) {
    font-size: 1em;
    width: auto;
  }
}

.stars {
  padding-left: 0;
  transform: translateY(-2px);
  width: 80px;
  padding-top: 5px;
  @media (--viewportMedium) {
    width: auto;
    padding-top: 0;
    padding-left: 0.5em;
  }
}
