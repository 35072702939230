@import '../../../../styles/customMediaQueries.css';


.sectionHeader {
  max-width: 1400px;
  margin: 0 auto;
  gap: 0.5rem;
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
  @media (max-width: 1024px) {
    margin: 0 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em 2rem 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em 2rem 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
    padding-bottom: 1em;
  }
}

.profileImages {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.profileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px;
  object-fit: cover;
}

.profileImage:first-child {
  margin-left: 0;
}

.headerTitle {
  font-family: Poppins;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  color: var(--marketplaceColor);
  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
}
.subHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0;
  @media (--viewportMedium) {
    align-items: center;
    justify-content: flex-start;
  }
}

.headerSubtext {
  font-size: 0.875rem;
  color: #666;
}

.yourMomentSection {
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  margin-bottom: 1em;
  margin-top: 3em;
}
@keyframes smoothScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1000%);
  }
}

.dragging {
  display: flex;
  cursor: grabbing;
  animation-play-state: paused;
}

.yourMomentCard {
  min-width: 270px;
  max-width: 270px;
  height: 350px;
  margin: 0 10px;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.imageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 20%);
  z-index: 1;
  pointer-events: none;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.cardTitle {
  position: absolute;
  font-family: Poppins;
  top: 0;
  right: 10px;
  color: white;
  font-weight: 500;
  margin: 10px;
  z-index: 2;
  font-size: 1rem;
}

.cardDescription {
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  bottom: -1em;
  left: 0;
  right: 0;
  padding: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  color: white;
  z-index: 2;
}

.cardDescription div {
  padding-bottom: 30px;
}
.buttonDiv {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  @media (--viewportMedium) {
    align-items: center;
    justify-content: flex-start;
  }
  @media (max-width: 1024px) {
    margin: 0 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.buttonDivLink {
  display: flex;
}

.exploreButton {
  display: block;
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
  background-color: var(--marketplaceColorSecond);
  color: white;
  border: none;
  border-radius: 11px;
  width: 180px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  @media (--viewportMedium) {
    padding: 1rem 1.5rem;
    width: 200px;
  }
}

.exploreButton:hover {
  background-color: #000000;
}
.yourMomentCard.clickable {
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: scale(0.99);
  }
}

@media screen and (max-width: 1024px) {
  .yourMomentCard {
    min-width: 240px;
    max-width: 240px;
    height: 320px;
    margin: 0 8px;
  }
  .yourMomentSection {
    margin-top: -7rem;
  }
}

@media screen and (max-width: 1200px) {
  .yourMomentCard {
    min-width: 240px;
    max-width: 240px;
    height: 320px;
    margin: 0 8px;
  }
  .yourMomentSection {
    margin-top: 2em;
  }
}
