@import '../../styles/customMediaQueries.css';


/* ModalInMobile is visible by default */
.root {
  width: 100%;
/*   position: sticky; */
  z-index: 1;

  @media screen and (max-width: 750px) {
  position: fixed;
  bottom: 5vh;
  z-index: 99;
  top: 0vh;
  width: 100vw;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
  left: 0vw;
  }
  @media screen and (max-height: 850px) and  (max-width: 750px) {
    height: 100%;
    position: absolute;
  }
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  position: sticky;
  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.modalContent {
  width: 100%;
  @media screen and (max-width : 750px) {
    position: sticky;
  }
  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }

}
.buttonMobil {
  position: absolute;
  bottom: 2em;
  left: 10%;
  transform: translateX(-10%); /* Horizontally center the button */
  z-index: 9;
  composes: marketplacePaperBackground from global;
  composes: marketplaceButtonStylesCustom from global;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eb7242;
  color: #fff;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  border-radius: 10px;
  text-align: center;
  animation-delay: 0.8s;
  transform: scale(0.8);
  width: 250px;
  padding: 1em 0.5em;
}

@media (min-width: 375px) {
  .buttonMobil {
    left: 35%;
    transform: translateX(-35%); /* Horizontally center the button */
  }
}
@media (min-width: 375px) {
  .buttonMobil {
    left: 45%;
    transform: translateX(-45%); /* Horizontally center the button */
  }
}
.buttonMobil:hover,
.buttonMobil:focus {
  outline: none;
  /* background-color: var(--marketplaceColorLight); */
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
  background-color: #353535;
  color: #fff;
}

