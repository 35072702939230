.root {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  border-radius: 12px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
/* Aspect ratio for is given inline */
.aspectPadding {
  border-radius: 20px;
}

.aspectBox {
  /* Layout - image will take space defined by aspect ratio wrapper */
  border-radius: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
