.root {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative;
}

.mapRoot {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
