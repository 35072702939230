@import '../../styles/customMediaQueries.css';


.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  column-count: 1;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;
  display: flex;
  align-items: flex-start;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 2px 0 6px 0;
  }
}

.infoIcon {
  margin-left: 5px;
  margin-top: 7px;
  width: 25px;
  height: 25px;

  bottom: 4px;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/icons/info-icon-thick.webp);
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.infoIcon:hover .infoText {
  visibility: visible;
}

.infoIcon .infoText {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: left;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid var(--marketplaceColorSecond);
  position: relative;
  display: block;
  left: -150px;
  top: 25px;
  z-index: 1;
  padding: 0.5em;
  width: 200px;
  text-wrap: wrap;

  @media (--viewportMedium) {
    top: 45px;
    left: 50%;
    margin-left: -190px;
    font-size: 16px;
  }
}
