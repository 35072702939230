@import '../../styles/customMediaQueries.css';


.root {
  /* Add loading color for the div */
  background-color: var(--marketplaceColor);

  width: 100%;
  position: relative;
}

.overlay,
.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.overlay {
  background: url('../../assets/mana_landscape.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
