@import '../../../styles/customMediaQueries.css';


:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  z-index: 18;
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 28px 0;
    /*     text-decoration: inherit; */

    @media (--viewportMedium) {
      margin: 28px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;

    &:hover {
      border-bottom: 2px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

.line {
  margin: 1.2% 1.2% 1% 1.2%;
  height: 30px;
  width: 1px;
  background-color: lightgrey;
  @media screen and (max-width: 1500px) {
    margin: 1.5% 1.2% 1% 1.2%;
  }
  @media screen and (max-width: 1000px) {
    margin: 2.5% 1.2% 1% 1.2%;
  }
  @media screen and (max-width: 600px) {
    margin: 3.5% 1.2% 1% 1.2%;
  }
  @media screen and (max-width: 500px) {
    margin: 6% 1.2% 1% 1.2%;
  }
}

.svg {
  left: 0;
  width: 0%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: 0.5s;
}

/* Desktop */
.root {
  /* Size */
  /* width: 100%; */
  width: 100vw;
  /* overflow: hidden; */

  overflow: visible;

  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 124px 0 0;

  background-color: transparent;
  transition: 0.5s;

  /* @apply --marketplacePaperBackground */

  /* shadows */
  /* box-shadow: var(--boxShadowLight); */
  /* box-shadow: 0 2px 10px 10px rgba(250, 250, 250, 0.85); */
  @media (--viewportLarge) {
    padding: 0 124px 0 0;
  }
}

.rootPaper {
  /* Size */
  width: 100%;
  /* width: 100vw; */
  /* overflow: hidden; */

  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 124px 0 0;

  /*   background-color: white; */

  transition: 0.5s;

  /* shadows */
  /* box-shadow: var(--boxShadowLight); */
  /* box-shadow: 0 2px 10px 10px rgba(250, 250, 250, 0.85); */
  @media (--viewportLarge) {
    padding: 0 124px 0 0;
  }
}
.rootPaperTransparent {
  width: 100%;
  height: var(--topbarHeightDesktop);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 124px 0 0;
  box-shadow: none;
  background-color: transparent;
  transition: 0.5s;
  @media (--viewportLarge) {
    padding: 0 124px 0 0;
  }
}
/* logo */
.logoLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */

.searchOuter {
  margin-top: 10px;
  z-index: 9;
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
}

.searchLink {
  opacity: 0;
}

.searchLink_active {
  display: none;

  flex-grow: 1;
  height: 72px;

  background-color: #fafafa;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  border-width: 1px 1px 0 1px;
  border-color: grey;
  border-style: solid;

  /* border-top-left-radius: 11px;
  border-bottom-right-radius: 11px; */

  padding-right: 0px;
  position: fixed;

  margin-left: 0vw;

  margin-top: 72px;

  width: calc(100% - 48px);
  left: 24px;
  @media (--viewportMedium) {
    width: calc(49% - 48px);
    left: 24px;
    display: block;
  }

  @media (--viewportLarge) {
    padding-left: 0;
    left: 36px;
    width: calc(61.3% - 72px);
  }
}

.search {
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.becomePartnerWrapper {
  @apply --TopbarDesktop_linkHover;
  height: 72px;
  width: 130px;
  display: flex;
  opacity: 1;
  margin-right: 18px;
  /* transition: 1s; */
}

.becomePartnerWrapper_hide {
  display: none;
  pointer-events: none;
}

.becomePartnerLink {
  composes: h5 from global;
  @apply --TopbarDesktop_linkHover;

  flex-shrink: 0;
  font-weight: bold;
  height: 100%;
  padding: 0;
  color: #1c7881;

  margin-bottom: 0;
  margin-right: 50px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  width: 130px;
  height: 38px;
  margin-top: 16px !important;
  border: 1px solid #a7a5a5;
  border-radius: 16px;
  @media (--viewportMedium) {
    font-size: 14px;
    margin: 0;
  }
}
.becomePartnerLink:hover {
  margin-top: 16px !important;
  background-color: white;
  border: 1px solid #838181;
  border-radius: 16px;
}
.becomePartnerLinkScrolled {
  composes: h5 from global;
  @apply --TopbarDesktop_linkHover;

  flex-shrink: 0;
  font-weight: bold;
  height: 100%;
  padding: 0;
  color: #1c7881;
  text-align: center;
  margin-bottom: 0;
  margin-right: 50px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);

  width: 130px;
  height: 38px;
  margin-top: 16px !important;
  border: 1px solid #a7a5a5;
  border-radius: 16px;
  @media (--viewportMedium) {
    font-size: 14px;
    margin: 0;
  }
}
.becomePartnerLinkScrolled:hover {
  margin-top: 16px !important;
  background-color: rgb(170, 169, 169);
  border: 1px solid #838181;
  border-radius: 16px;
}

.becomePartner {
  @apply --TopbarDesktop_label;
  /* margin-right: 20px; */
  width: 130px;
  height: 150px;
  margin-top: 5px;
  margin-left: -2px;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  composes: h5 from global;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  /* color: white; */
  color: white;
  position: relative;
}
.inboxLinkScrolled {
  @apply --TopbarDesktop_linkHover;
  composes: h5 from global;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  /* color: white; */
  color: rgb(63, 62, 62);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDotMenuDrawer {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -58px;
  right: -95px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
  z-index: 1;
  @media (--viewportMLarge) {
    top: -58px;
    right: -125px;
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: relative;
  top: 0px;
  left: -5px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
  z-index: 1;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  margin: 0 0 0 0.1%;
  padding: 0;
  /*   color: white; */
  position: relative;
  /*  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(351deg) brightness(101%) contrast(101%); */
  top: -7px;
}
.loginLinkScrolled {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  margin: 0 0 0 0.1%;
  padding: 0;
  color: white;
  position: relative;
}

.signup,
.login {
  composes: h5 from global;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
}

.logoutButton {
  composes: marketplaceLinkStyles from global;
  /* Font is specific to this component */
  composes: h5 from global;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;
}

.aboutLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  position: relative;

  color: var(--matterColor);
}
.aboutLink:hover {
  color: var(--matterColorDark);
}

.about {
  composes: h5 from global;
  @apply --TopbarDesktop_label;
}

.about:hover {
  cursor: pointer;
}

.active {
  border-bottom: 2px solid var(--marketplaceColor);
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColor);
}

.active:hover {
  text-decoration: none;
}

/* .formControllLanguage *{
  border-bottom: none !im;
} */

.userIcon {
  width: 30px;
  height: 30px;
  margin-top: 28px;
}
.userIcon2 {
  width: 30px;
  height: 30px;
  margin-top: 28px;
}

.firstLinks {
  height: 72px;
  width: 340px;
  display: flex;
  opacity: 1;
  transition: 0.5s;
}

.firstLinks_hide {
  opacity: 0;
  height: 72px;
  /*   width: 340px;
 */
  display: flex;
  transform: translateX(340px);
  transition: 0.5s;
  width: 0;
}

.openButton {
  width: 100px;
  right: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  top: 0;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(351deg) brightness(101%) contrast(101%);
  @media (--viewportMedium) {
    right: 30px;
  }
}

.openButtonScrolled {
  width: 80px;
  right: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  color: #4a4a4a;
  top: 0;
  z-index: 10;
  filter: invert(0%) sepia(0%) saturate(7457%) hue-rotate(138deg) brightness(107%) contrast(96%) !important;
  @media (--viewportMedium) {
    right: 20px;
    top: -7px;
  }
  @media (--viewportMaxSmallDevice) {
    top: -10px;
    right: 5px;
  }
}
.openButtonScrolled:hover {
  cursor: pointer;
}
.openButton:hover {
  cursor: pointer;
}

.openButton span {
  @apply --TopbarDesktop_label;

  font-size: 14px;
  padding-left: 15px;
  padding-right: 5px;

  display: none;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.drawer {
  height: auto;
  min-height: 100vh;
  composes: marketplacePaperBackground from global;
  overflow: scroll;

  width: 100vw;

  @media (--viewportMedium) {
    width: 85vw;
    overflow: hidden;
  }

  @media (--viewportMLarge) {
    width: 65vw;
    overflow: hidden;
  }
}

.backgroundContainer {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  backdrop-filter: blur(5px);
  z-index: 0;
  animation: backgroundstartanimation 1s;

  width: 0vw;

  @media (--viewportMedium) {
    width: 15vw;
  }

  @media (--viewportMLarge) {
    width: 35vw;
  }
}

@keyframes backgroundstartanimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.menuWrapper {
  margin-bottom: 10vh;
  height: 80vh;
  width: 80%;

  margin-left: 30px;
  margin-top: 5vh;

  @media (--viewportMedium) {
    margin-left: 100px;
    margin-top: 5vh;
    width: calc(95% - 100px);
  }
  @media (--viewportMLarge) {
  }
}

/* selector which is in the TopbarDesktop drawer */
.languageSelector {
  /*  composes: h5 from global;
  @apply --TopbarDesktop_label;
  margin: 18px 0 18px auto;
  min-width: max-content;
  right: 30px;
  display: none !important;
  color: white;
  border-bottom: none !important; */
  @media (--viewportMedium) {
    display: inline-flex !important;
    position: relative;
    right: unset;
    top: 0;
    margin: 18px 15px 18px 0;
    width: 84px;
  }

  @media (--viewportLarge) {
    margin: 18px 16px;
  }
}
.langCss {
  border-bottom: none;
}
.languageSelector option {
  color: var(--marketplaceColor);
  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.formControllLanguage .MuiInput-underline:before,
.formControllLanguage .MuiInput-underline:after {
  border-bottom: none !important;
}
/* selector which is in the menu drawer */
.languageSelector2 {
  composes: h5 from global;
  @apply --TopbarDesktop_label;
  margin-left: 0px;

  @media (--viewportMedium) {
    margin-left: 100px;
    display: none !important;
  }
}

.languageSelector2 option {
  color: var(--marketplaceColor);
  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.menuLeftWrapper {
  position: relative;

  @media (--viewportMedium) {
    float: left;
    animation: menuLeftWrapperstartanimation 1.5s;
    height: 80%;
    width: 40%;
    border-right: 1px solid #1c7881;
  }
  @media (--viewportSmallDevice) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@keyframes menuLeftWrapperstartanimation {
  0% {
    height: 0%;
  }

  85% {
    height: 0%;
  }

  100% {
    height: 80%;
  }
}

.menuRightWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    width: calc(100% - 40%);
    float: right;
    padding-left: 26px;
    height: 80vh;
  }
}

.HeaderWrapperRow {
  width: 100%;
  overflow: visible;
  margin-bottom: 1.5em;
  padding-bottom: 4.5vw;
  height: 2em;

  @media (--viewportMedium) {
    height: 2.1em;
  }

  @media (--viewportMLarge) {
    height: 3.2em;
  }
  @media screen and (min-width: 1400px) {
    margin-bottom: 0em;
    padding-bottom: 3vw;
  }
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    margin-bottom: 0em;
    padding-bottom: 4vw;
  }
}
.morePadding {
  @media screen and (min-width: 700px) and (max-width: 900px) {
    padding-bottom: 6.5vw;
  }
}

.HeaderWrapperRowLast {
  width: 100%;
  margin-bottom: 20px;
  height: 2em;

  @media (--viewportMedium) {
    height: 3em;
  }

  @media (--viewportMLarge) {
    height: 7em;
  }
}

.Header {
  text-align: left;
  margin-top: 0;
  width: 100%;
  font-family: Noto Sans JP;
  color: var(--marketplaceColor);
  transform: skewX(0deg) skewY(0deg);
  opacity: 1;
  color: #353535;

  font-size: 28px;
  line-height: 28px;

  @media (--viewportMedium) {
    font-size: 34px;
    line-height: 34px;
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    /* line-height: 54px;*/
  }
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    font-size: 25px;
  }
}

.Header1 {
  animation: headerstartanimation 0.2s;
}

.Header2 {
  animation: headerstartanimation 0.35s;
}

.Header3 {
  animation: headerstartanimation 0.5s;
}

.Header4 {
  animation: headerstartanimation 0.65s;
}

.Header5 {
  animation: headerstartanimation 0.8s;
}

.Header7 {
  animation: headerstartanimation 0.35s;
}

.Header6 {
  animation: headerstartanimation 0.95s;
  color: #eb7242;
  text-shadow: 0px 6px 4px rgba(182, 180, 180, 0.61);
  @media (min-width: 767px) and (max-width: 1023px) {
    font-size: 25px;
    line-height: 34px;
  }
}

@keyframes headerstartanimation {
  0% {
    opacity: 0;
    margin-top: 3.5em;
    transform: skewX(5deg) skewY(15deg);
  }

  40% {
    opacity: 0;
    margin-top: 3.5em;
    transform: skewX(5deg) skewY(15deg);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: skewX(0deg) skewY(0deg);
  }
}

.sideHeader {
  animation: sideHeaderstartanimation 1.6s;
  margin-top: 0;
  transform: skewX(0deg) skewY(0deg);
  color: #353535;
}

.sideText {
  animation: sideHeaderstartanimation 1.8s;
  margin-top: 0;
  transform: skewX(0deg) skewY(0deg);
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2em;
}
.sideText span > a {
  color: #1c7881;
}
.image1 {
  float: left;
  animation: sideHeaderstartanimation 2.2s;
}
.scrolled {
  background-color: white;
  box-shadow: var(--boxShadow);
}
.notScrolled {
  background-color: transparent;
}

.image2 {
  /* float: left;
  margin-left: 60px;
  margin-top: -70px;
  float: left;
  margin-bottom: 30px;
  animation: sideHeaderstartanimation 2.2s; */
  @media (max-width: 768px) {
    float: left;
    margin-left: 60px;
    margin-top: -65px;
  }
  @media (min-width: 1024px) {
    margin-left: 60px;
    margin-top: -65px;
  }
}

@keyframes sideHeaderstartanimation {
  0% {
    opacity: 0;
    margin-top: -50px;
    transform: skewX(5deg) skewY(5deg);
  }

  60% {
    opacity: 0;
    margin-top: -50px;
    transform: skewX(5deg) skewY(5deg);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: skewX(0deg) skewY(0deg);
  }
}

.spacer {
  width: 80%;
  height: 1.5px;
  background-color: var(--marketplaceColorSecond);
  margin-bottom: 20px;
}

.closeButton {
  float: right;
  position: fixed;
  right: 30px;
  top: 0px;
  z-index: 999;
}

.logInButton {
  margin: 5vh 0 !important;
  /* composes: marketplaceButtonStyles from global; */
  composes: marketplaceButtonStyles from global;
  /* box-shadow: 0px 4px 15px rgba(28, 129, 114, 0.4); */
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  display: block;
  width: 300px;
  border-radius: 10px;

  /* filter: drop-shadow(0px 4px 15px rgba(28, 129, 114, 0.4)); */
  animation: logInButtonstartanimation 1s;
  /* position: fixed;
  bottom: 5vh; */

  position: relative;
  margin-top: 5vh;
  margin-bottom: 5vh;

  @media (--viewportMedium) {
    margin-top: 0vh;
    margin-left: 0vw;
  }
}
.logInButton:hover {
  background-color: var(--matterColor) !important;
}

@keyframes logInButtonstartanimation {
  0% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.heroButton {
  composes: marketplacePaperBackground from global;
  composes: marketplaceButtonStyles from global;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eb7242;
  color: #fff;

  z-index: 999;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  font-size: large;
  border-radius: 10px;
  text-align: center;
  animation-delay: 0.8s;
  transform: scale(0.8);

  width: 155px;

  @media (--viewportMedium) {
    width: 225px;
    min-height: 50px;
  }
}
.heroButton:hover {
  background-color: #353535;
}

.heroButtonMobile {
  composes: marketplacePaperBackground from global;
  composes: marketplaceButtonStylesCustom from global;
  @apply --composes: marketplaceButtonFontStyles from global;;

  padding-top: 8%;
  z-index: 999;
  box-shadow: 0 4px 15px rgba(235, 114, 66, 0.4);
  background-color: #eb7242;
  color: #fff;
  animation-delay: 0.8s;
  transform: scale(0.8);
  text-align: center;
  border-radius: 10px;
  display: block;

  width: 130px;

  @media (--viewportMedium) {
    width: 190px;
  }
}

.check {
  position: absolute;
  height: 50px !important;
  width: auto;
  display: flex;
  opacity: 1;
  margin-top: -2px !important;
  transition: 0.5s;
  right: 50px;

  @media only screen and (max-width: 1200px) {
    margin-top: 0;
    left: 133px;
    height: 28px;
    width: 190px;
  }

  @media only screen and (max-width: 1200px) {
    left: 170px;
    width: 210px;
  }
  @media only screen and (max-width: 800px) {
    left: 150px;
    width: 200px;
  }
  @media only screen and (min-width: 1201px) {
    margin-top: 0;
    right: calc(55vw - 150px);
    height: 72px;
  }
}

.check_hide {
  position: absolute;
  opacity: 0;
  margin-top: -3em;
  transition: 0.5s;
  right: 50px;

  @media only screen and (max-width: 1200px) {
    left: 170px;
    width: 210px;
  }
  @media only screen and (max-width: 800px) {
    left: 150px;
    width: 200px;
  }

  @media only screen and (min-width: 1201px) {
    right: calc(55vw - 150px);
  }
}

/*added 24.04.2023*/

.activeClassName h2 {
  color: #1c7981da;

  text-shadow: 0px 6px 4px rgba(182, 180, 180, 0.61);
}

.Header:hover {
  text-shadow: 0px 6px 4px rgba(182, 180, 180, 0.61);
}
.openButton:hover {
  filter: invert(37%) sepia(40%) saturate(856%) hue-rotate(137deg) brightness(94%) contrast(83%);
}
.openButtonScrolled:hover {
  filter: invert(37%) sepia(40%) saturate(856%) hue-rotate(137deg) brightness(94%) contrast(83%);
}
.about:hover {
  filter: brightness(0) saturate(100%) invert(61%) sepia(20%) saturate(6684%) hue-rotate(335deg)
    brightness(98%) contrast(87%);
}

.createListingLink {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}
