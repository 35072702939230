.container {
  display: flex;
  width: 100%;
  
  background-color: transparent;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
  margin: 2em 0;
  @media screen and (min-width: 786px) {
    height: 330px;

  }
}

.sectionh2Title {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 1em;
  
  @media (max-width: 1024px) {
    margin: 0 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.logosDesktop {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: 1500px;
  /* gap: 15px; */
  @media screen and (max-width: 1550px) and (min-width: 768px) {
    gap: 10px;
    max-width: 100%;
    padding-left: 7%;
    padding-right: 7%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.logo {
  display: block;
  width: 140px;
  height: auto;
  @media screen and (max-width: 1450px) {
    width: 130px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 80px;
    height: auto;
  }
  @media screen and (max-width: 500px) {
    width: 60px;
    height: auto;
  }
}

.logosMobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }
}

.logosMobileRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
