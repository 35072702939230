.wrapper {
  display: flex;
  height: 400px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  position: relative;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

.cover {
  object-fit: fill;
  height: 400px;
  width: 100%;
  @media screen and (max-width: 768px) {
    object-fit: cover;
  }
}

.headerText {
  color: white;
  background-color: transparent;
  text-align: center;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 45px;
  line-height: 62px;
  max-width: 600px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.titleWrapper {
  width: 100%;
  position: absolute;
  display: flex;
  top: 70px;
  height: 100%;
  align-items: center;
  justify-content:flex-start;
  flex-direction: column;
  gap: 50px;
  z-index: 1;
  @media screen and (max-width: 768px) {
    top: 60px;
  }
}

.searchBar{
  justify-content: center;
  justify-self: center;
}
