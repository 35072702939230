.logo {
  width: 120px;

  @media (--viewportMedium) {
    width: 140px;
  }
}

.logoWhite {
  width: 105px;

  @media (--viewportMedium) {
    width: 115px;
    padding-top: 1px;
  }
}
