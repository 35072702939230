@import '../../../../styles/customMediaQueries.css';


.experience-card {
  background: white;
  border-radius: 20px;
  padding: 0.8em;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 7px 5px 0px;
  &:hover {
    scale: 1.01;
  }
  &:active {
    scale: 0.98;
  }
}

.horizontal-view {
  display: flex;
  height: 100%;
}

.vertical-view {
  height: 100%;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-view .card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: auto;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}
.vertical-view .image-container {
  height: 72%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  min-width: 220px;

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1; /* Ensure it overlays the image */
  pointer-events: none; /* Allow interactions with child elements */
  border-radius: 20px;
  @media (--viewportMedium) {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.8)
    );
  }
}

.vertical-view .image-container::before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.8)
  );
}

.horizontal-view .card-content {
  padding-left: 30px;
}

.card-content {
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 24px;
  left: 0;
}

.price-container {
  text-align: center;
  line-height: 24px;
  color: var(--marketplaceColor);
}

.price-label {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  @media screen and (max-width: 700px) {
    font-size: 13px;
  }
}

.price-value {
  font-size: 1em;
  font-weight: 800;
  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

.card-description {
  font-size: 0.8em;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin: auto 0;
}

.vertical-view .card-description {
  height: 100%;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding-bottom: 20px;
}

.discover-button {
  border: 1px solid var(--marketplaceColorSecond);
  padding: 0.6em;
  border-radius: 5px;
  color: var(--marketplaceColorSecond);
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    border: 1px solid #c73f0a;
  }
}

.image-description {
  position: absolute;
  bottom: 20px;
  left: 6px;
  padding: 0 6px;
  z-index: 2;
  color: white;
  width: 100%;
}

.duration-badge {
  font-size: 0.8em;
  font-weight: 400;
  color: white;
}

.card-title {
  color: white;
  font-size: 1em;
  font-weight: 600;
  margin-top: -1px;
  line-height: 1.1em;
  @media (--viewportMedium) {
    margin-top: 0.2em;
  }
}

.horizontal-image {
  @media screen and (max-width: 700px) {
    min-height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .card-title {
    font-size: 12px;
  }

  .duration-badge {
    font-size: 12px;
  }
}

@media screen and (max-width: 700px) {
  .image-container {
    height: 65%;
  }
}

@media screen and (max-width: 600px) {
  .experience-card {
    padding: 5px !important;
  }

  .card-description {
    font-size: 10px;
    line-height: 13px;
    margin: 5px 0;
  }

  .vertical-view .card-description {
    font-size: 10px;
    line-height: 13px;
    padding-bottom: 8px;
  }

  .discover-button {
    padding: 5px;
    font-size: 10px;
  }

  .price-container {
    font-size: 18px;
  }

  .price-label {
    font-size: 11px;
    line-height: 16px;
  }

  .price-value {
    font-size: 13px;
  }

  .image-container {
    min-height: 180px;
    min-width: 120px;
  }

  .horizontal-view .card-content {
    padding-left: 15px;
  }

  .card-title {
    font-size: 13px;
  }

  .duration-badge {
    font-size: 11px;
  }

  .card-footer {
    bottom: 12px;
  }

  .vertical-image-small img {
    min-height: 100% !important;
    height: 170px;
  }
}
