.categoryButton,
.categoryButtonActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1c7881;
  font-size: 1rem;
  min-width: 3rem;
  max-width: 5rem;
  border: none;
  margin: 0 2% 0 0.5%;
  cursor: pointer;
  height: auto;
  padding: 0;
}

.categoryButtonActive {
  border-bottom: 1.5px solid #1c7881;
  flex-shrink: 0;
}

.categoryButton:hover {
  color: var(--marketplaceColorDark);
}

.categoryButton:active {
  transform: scale(0.97);
}

.categoryIcon {
  width: 33px;
  height: 33px;
}

.categoryText {
  padding: 10px;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
}

.desktopCategories {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: fit-content;
}

.desktopSlider {
  width: 90%;
  margin: 15px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.mobileCategories {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.mobileSlider {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin: 0 1%;
  width: 100%;
}

.separator {
  width: 1.5px;
  height: 70%;
  background-color: var(--marketplaceColor);
  margin: 0 5px;
  transform: translateY(-12px);
}

@media screen and (max-width: 950px) {
  .categoryText {
    font-size: 7.5px;
  }

  .categoryIcon {
    width: 28px;
    height: 28px;
  }
}
/* Booking */

.outerWrapper {
  width: 100%;
  margin-top: 1.5em;
  padding-bottom: 1em;
}

.container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionTitle {
  padding-bottom: 0.5em;
  text-align: center;
}

.AllCards {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  padding-top: 25px;
  margin: auto;
}

.oneCard {
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  max-height: 30rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s;
}

.oneCard:hover {
  transform: scale(1.03);
}
.oneCard:active {
  transform: scale(0.98);
}

.img {
  height: 250px;
  max-width: 93%;
  margin: 13px;
  object-fit: cover;
  border-radius: 5px;
}

.spanDescription {
  position: relative;
  display: flex;
  flex-direction: column;
}

.listDescriptionWrapper {
  padding-left: 20px;
  padding-right: 15px;
  margin-top: -5px;
  padding-bottom: 4px;
}

.name {
  font-weight: bold;
  font-size: 0.7em;
  width: 80%;
  height: 60px;
  color: rgb(44, 44, 44);
  line-height: 16px;
  padding-bottom: 10px;
}

.descriptionTxt {
  display: flex;
  flex-direction: column;
  height: 60px;
  margin: 8px 0 0;
}

.priceSubtitle {
  position: absolute;
  font-size: 0.7em;
  color: #1c7881;
  top: 53px;
  padding-bottom: 5px;
}

.price {
  font-size: 1em;
  height: 60px;
  color: #1c7881;
}

.pinlogo {
  padding: 0 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5em;
}

.location {
  font-weight: bold;
  font-size: 0.7em;
  height: 60px;
  color: rgb(44, 44, 44);
  margin-left: 5px;
}

.projectype {
  position: absolute;
  top: 10%;
  right: 4%;
}

.projectRatings {
  margin: -12px -10px 0px -3px;
  display: flex;
  align-items: center;
}

.emptySpacer {
  height: 40px;
  width: 10px;
}

@media screen and (min-width: 1400px) {
  .AllCards {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
  .container {
    max-width: 1440px;
  }
}

@media screen and (min-width: 577px) and (max-width: 1024px) {
  .AllCards {
    width: 98%;
    grid-template-columns: repeat(2, 1fr);
  }
  .name {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 576px) {
  .AllCards {
    grid-template-columns: 1fr;
  }
}
