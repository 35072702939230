@import '../../styles/customMediaQueries.css';

.impactWrapper {
  display: flex;
  width: 90vw;
  z-index: 1;
  height: auto;
  margin: 1rem auto;
}

.circleWrapper {
  display: flex;
  width: 100vw;
  height: auto;
  margin-bottom: 1rem;
}

.impactHeader {
  composes: h2 from global;
  width: 100%;
  text-align: left;
  color: var(--successColor);
}

.circle {
  position: relative;
  width: 30em;
  height: 30em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 2em auto 2em;
  border: solid 4px var(--successColor);
  z-index: 1;

  @media (min-width: 280px) and (max-width: 414px) {
    width: 20em;
    height: 20em;
  }
}

.circleContainer>* {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6em;
  height: 6em;
  margin: -3em;
  z-index: 4;
}

.background {
  position: absolute;
  height: 8em;
  width: 8em;
  background-color: white;
  border-radius: 50%;
  display: block;
  z-index: 2;
  opacity: 1;

  @media (min-width: 280px) and (max-width: 414px) {
    height: 5.5em;
    width: 5.5em;
  }
}

.circleContainer img {
  display: block;
  width: 100%;
  border-radius: 50%;

  @media (min-width: 280px) and (max-width: 414px) {
    width: 70%;
  }
}

.text {
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 75px;
  z-index: 3;

  @media (min-width: 280px) and (max-width: 414px) {
    padding: 60px;
  }
}

.text h3 {
  font-size: 32px;
  line-height: 100%;
  font-family: Noto Sans JP;

  @media (min-width: 280px) and (max-width: 414px) {
    font-size: 13px;
    line-height: 100%;
  }
}

.text p {
  @media (min-width: 280px) and (max-width: 414px) {
    font-size: 10px;
    line-height: 140%;
  }
}

.circleContainer:hover img {
  transition: 0.25s;
  opacity: 0.7;
}

.circleContainer img:hover {
  opacity: 1;
  transform: scale(1.3);
  box-shadow: 0 0 9px 9px #ededed;

  @media (min-width: 280px) and (max-width: 414px) {
    transform: scale(1.2);
  }
}

.circleContainer> :nth-of-type(1) {
  transform: rotate(30deg) translate(15em) rotate(-30deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(25deg) translate(11em) rotate(-25deg);
  }
}

.circleContainer> :nth-of-type(2) {
  transform: rotate(90deg) translate(15em) rotate(-90deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(86deg) translate(11em) rotate(-86deg);
  }
}

.circleContainer> :nth-of-type(3) {
  transform: rotate(150deg) translate(15em) rotate(-150deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(150deg) translate(10em) rotate(-150deg);
  }
}

.circleContainer> :nth-of-type(4) {
  transform: rotate(210deg) translate(15em) rotate(-210deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(205deg) translate(9em) rotate(-205deg);
  }
}

.circleContainer> :nth-of-type(5) {
  transform: rotate(270deg) translate(15em) rotate(-270deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(275deg) translate(9em) rotate(-270deg);
  }
}

.circleContainer> :nth-of-type(6) {
  transform: rotate(330deg) translate(15em) rotate(-330deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(335deg) translate(10em) rotate(-335deg);
  }
}

.backgroundContainer> :nth-of-type(1) {
  transform: rotate(24.5deg) translate(9em) rotate(-30deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(16deg) translate(6em) rotate(-30deg);
  }
}

.backgroundContainer> :nth-of-type(2) {
  transform: rotate(106deg) translate(10em) rotate(-90deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(106deg) translate(7em) rotate(-90deg);
  }
}

.backgroundContainer> :nth-of-type(3) {
  transform: rotate(172deg) translate(15em) rotate(-150deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(178deg) translate(11em) rotate(-150deg);
  }
}

.backgroundContainer> :nth-of-type(4) {
  transform: rotate(215deg) translate(20em) rotate(-210deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(213deg) translate(14em) rotate(-210deg);
  }
}

.backgroundContainer> :nth-of-type(5) {
  transform: rotate(254deg) translate(18em) rotate(-270deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(253deg) translate(12em) rotate(-270deg);
  }
}

.backgroundContainer> :nth-of-type(6) {
  transform: rotate(308deg) translate(12em) rotate(-330deg);

  @media (min-width: 280px) and (max-width: 414px) {
    transform: rotate(306deg) translate(8em) rotate(-330deg);
  }
}
