.list1 {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 26px;
  @media screen and (max-width: 1000px) {
    margin-top: 0px;
  }
}

.logo {
  max-width: 150px;
  height: auto;
  @media screen and (max-width: 600px) {
    max-width: 100px;
  }
}

@media screen and (min-width: 768px) {
  .logo {
    max-width: 120px;
  }
}

@media screen and (min-width: 1024px) {
  .list1 div {
    margin-top: 1em;
  }
}

@media screen and (min-width: 1440px) {
  .logo {
    max-width: 220px;
  }
}

.logos {
  display: flex;
  flex-direction: row;
}

.socialMediaIcon {
  width: 60px;
  height: 60px;
}

.socialMediaBtns {
  display: flex;
  width: fit-content;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.footerTopText {
  margin-bottom: 10px;
}

.wsa {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.trustPilotLogo {
  width: 80px;
  margin-top: -4px;
  height: auto;
  @media screen and (max-width: 600px) {
    width: 60px;
  }
}

.trustPilotRating {
  color: white;
  max-width: 120px;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    max-width: 75px;
    font-size: 13px;
  }
}


.trustPilot{
  display: flex;
  flex-direction: column;
  align-items: left;
  gap:8px;
  padding-right: 10px;
  padding-top: 0;
  @media screen and (max-width: 600px) {
    padding-right: 0px;
    gap: 5px;
  }
}
