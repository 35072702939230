@import '../../styles/customMediaQueries.css';


.logo {

  @media (--viewportMedium) {
  }
}

.logoWhite {

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.logoWhiteCropped{
  display: none;
  max-width: 35px;
  height: 35px;
  @media screen and (max-width: 550px) {
    display: flex;
  }

}

/* .logoMobile {
  & path {
    fill: var(--marketplaceColor);
  }
} */
