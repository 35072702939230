@import '../../../../styles/customMediaQueries.css';


.mission-steps-container {
  width: 100%;
  position: relative;
  margin: 0;
  margin-top: 90px;
  padding-bottom: 60px;
}

.mission-container {
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    margin: 0 2em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.main-title {
  text-align: left;
  font-family: var(--fontFamily);
  color: var(--marketplaceColor);
  font-size: 1.6em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.sectionh2Title {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}
.subtitle {
  color: var(--matterColorDark);
  font-size: 1em;
  font-weight: 400;

  margin-bottom: 1.5em;
  width: 80%;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    width: 100%;
  }
}

.section-header {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportMedium) {
    align-items: flex-start;
  }
}

.experiences-grid {
  display: grid;
  grid-template-columns: 1.2fr 2.8fr;
  gap: 60px;
  margin: 0 auto;
}

.container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 50px;
  height: 100%;
}

.row:nth-child(n) {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 60px;
  height: 100%;
}

.row:nth-child(2) {
  grid-template-columns: 1fr 2fr;
  gap: 50px;
}

.row:nth-child(n) .row-image {
  height: 100%;
  width: 100%;
}

.row:nth-child(n) .row-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  &:hover {
    scale: 1.01;
  }
}

.small-cards-container {
  display: none;
}

@media screen and (max-width: 900px) {
  .experiences-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .small-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }

  .small-cards-container .row {
    height: calc(50% - 10px);
  }

  .small-cards-container .row:nth-child(n) {
    width: 100%;
    height: 100%;
    display: block;
  }

  .small-cards-container .row:nth-child(n) .image-container {
    width: 100%;
    height: 100%;
  }

  .container {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .experiences-grid {
    gap: 15px;
  }

  .small-cards-container {
    gap: 15px;
  }

  .main-title {
    font-size: 28px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .experiences-grid {
  }

  .main-title {
    font-size: 24px;
    line-height: 28px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .small-cards-container {
    gap: 15px;
  }

  .row:nth-child(n) {
    gap: 10px;
    height: 100% !important;
  }
}
