.container,
.containerSecond {
  position: fixed;
  right: 15px;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 2000;
  padding: 0px;
}

.button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px !important;
}

.imageButton {
  width: 60px;
  height: 60px;
  border-radius: 10px 0 0 0;
  filter: brightness(1.2);
}

.buttonContainer {
  background-color: #1c7881;
  cursor: pointer;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px 0 0 10px;
  transition: all ease 0.1s;
}

.buttonContainer:hover {
  scale: 1.05;
  transform: translateX(-2px);
}

.buttonContainer:focus {
  scale: 0.95;
}

.phoneIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.quickMenuFullContainer {
  background-color: #1c7881;
  width: 240px;
  padding: 20px 20px;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}

.image {
  width: calc(240px * 0.5);
  height: calc(240px * 0.5);
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

.closeButton {
  border: none;
  position: absolute;
  top: 3px;
  left: 5px;
  cursor: pointer;
  opacity: 0.8;
  background: none;
}

.closeButton:hover {
  opacity: 1;
  transition: all ease 0.1s;
  scale: 1.03;
}

.text {
  color: white !important;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.25px;
  margin: 0px;
  margin-top: 20px;
  flex-direction: column;
  display: flex;
}

.phoneNumberHref {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 26px;
  color: white !important;
}

.phoneEmailContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.phoneEmailContainer a:hover {
  scale: 1.05;
}

.phoneEmailContainer a:focus {
  scale: 0.95;
}

.whatsappContainer {
  position: fixed;
  right: 15px;
  bottom: -10px;
  transform: translateY(-50%);
  z-index: 2000;
  padding: 0px;
  @media screen and (max-width: 380px) {
    right: 80px;
  }
  @media screen and (max-width: 350px) {
    right: 60px;
  }
}

.whatsappButton {
  border: none;
  cursor: pointer;
  background: none;
}

.whatsappImg {
  width: 50px;
  height: 50px;
}

@media (min-width: 801px) {
  .whatsappContainer {
    display: none;
  }
}

@media (max-width: 800px) {
  .container {
    display: none;
  }
}
